.note {
    margin:1em;
    padding:1em;
    text-decoration:none;
    color: rgba(29, 89, 78, 1);
    background: rgba(242, 202, 4, 1);
    box-shadow: 5px 5px 7px rgba(242, 135, 4, 0.5);
}

.btn {
    font-size: 2rem;
    border: none;
    background: transparent;
    color: rgba(242, 48, 48, 1);
}

.btn:hover {
    color: rgba(242, 135, 4, 1);
}

.title {
    display: block;
    border: none;
    resize: none;
    outline: none;
    overflow: auto;
    max-height: auto;
    font-size: 2rem;
    font-weight: bold;
    background: transparent;
    color: rgba(0, 32, 48, 0.9);
}

.title:focus,
.content:focus {
    outline: none;
}

.content {
    display: block;
    border: none;
    outline: none;
    width: 100%;
    height: 150px;
    font-size: 1rem;
    font-weight: normal;
    background: transparent;
    color: rgba(29, 89, 78, 1);
    overflow: hidden;
}